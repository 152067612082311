<template>
  <ykc-drawer
    :title="'充电评价回复'"
    :show="true"
    :on-close="onAddDrawerClose"
    :before-cancel="onAddDrawerCancel"
    :before-ensure="submitForm">
    <div class="drawer-main-body">
      <ykc-detail-plus :labelWidth="80">
        <ykc-detail-item-plus label="电站名称">
          {{ detailInfo.stationName || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="充电单号">
          {{ detailInfo.tradeSeq || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="用户账号">
          {{ detailInfo.userAccount || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="用户姓名">
          {{ detailInfo.nickName || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="显示状态">
          {{ detailInfo.displayStatus === 1 ? '显示' : '隐藏' }}
        </ykc-detail-item-plus>
        <div class="clearfix"></div>
        <el-divider></el-divider>
        <ykc-detail-item-plus label="评价时间">
          {{ detailInfo.createdTime || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="用户评分">
          <StarScore :value="detailInfo.userScore || 0" :readonly="true" />
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="评价内容">
          {{ detailInfo.evaluate || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="附件">
          <div class="pics-box" v-if="detailInfo.imgUrls && detailInfo.imgUrls.length">
            <el-image
              v-for="(url, index) in detailInfo.imgUrls"
              :src="url"
              :key="index"
              style="width: 100px; height: 100px"
              :zoom-rate="1.2"
              :max-scale="7"
              :min-scale="0.2"
              :preview-src-list="detailInfo.imgUrls"
              :initial-index="4"
              fit="cover" />
          </div>
          <div v-else>——</div>
        </ykc-detail-item-plus>
        <div class="clearfix"></div>
        <el-divider></el-divider>
        <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
          <ykc-form-item label="回复内容" prop="reply">
            <ykc-input
              maxlength="200"
              type="textarea"
              placeholder="请输入回复内容"
              v-model="ruleForm.reply"></ykc-input>
          </ykc-form-item>
        </ykc-form>
      </ykc-detail-plus>
    </div>
  </ykc-drawer>
</template>

<script>
  import StarScore from './StarScore.vue';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { orderPage } from '@/service/apis';

  export default {
    data() {
      return {
        detailInfo: {},
        ruleForm: {
          reply: '',
        },
        rules: {
          reply: [{ required: true, message: '请输入回复内容', trigger: 'blur' }],
        },
      };
    },
    props: {
      id: {
        required: true,
      },
    },
    components: {
      StarScore,
    },
    mounted() {
      this.getOrderDetail();
    },
    methods: {
      getOrderDetail() {
        orderPage
          .orderEvaluateDetail({
            evaluateId: this.id,
          })
          .then(res => {
            this.detailInfo = res;
            this.ruleForm.reply = res.reply;
          })
          .catch(err => {
            console.log(err);
          });
      },
      submitForm() {
        return new Promise(resolve => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              orderPage
                .orderEvaluateReply({
                  evaluateId: this.id,
                  reply: this.ruleForm.reply,
                })
                .then(() => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  this.$emit('close');
                  this.$emit('confirm');
                  resolve();
                });
            }
          });
        });
      },
      onAddDrawerClose(done) {
        done();
        this.$emit('close');
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
            this.$emit('close');
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            this.$emit('close');
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .clearfix {
    clear: both;
  }
  .pics-box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
</style>
