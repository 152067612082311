<template>
  <scroll-layout class="fleet-match-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <ykc-table
      ref="YkcTable"
      operateFixedType="right"
      :data="tableData"
      :title="tableTitle"
      :columns="tableColumns"
      :operateButtons="tableOperateButtons.filter(item => item.enabled())">
      <template #headerButtons>
        <div class="table-header-button">
          <ykc-button
            v-rbac="'operation:evaluate:list:keyword'"
            type="plain"
            @click="toBlockkeywords">
            屏蔽关键字
          </ykc-button>
          <ykc-button v-rbac="'operation:evaluate:list:export'" type="plain" @click="doExportFile">
            导出
          </ykc-button>
        </div>
      </template>
      <ykc-pagination
        align="right"
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.size"
        :current-page.sync="pageInfo.current"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
    <Reply
      v-if="showDrawer"
      :id="evaluateId"
      @confirm="requestList"
      @close="showDrawer = false"></Reply>
  </scroll-layout>
</template>

<script>
  import { code, getLastTime, offlineExport } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import Reply from './Reply.vue';
  import { orderPage } from '@/service/apis';

  export default {
    name: 'orderEvaluationList',
    components: {
      Reply,
    },
    data() {
      return {
        evaluateId: '',
        showDrawer: false,
        searchParams: {
          date: [],
          startTime: '',
          endTime: '',
          stationName: '',
          userAccount: '',
          tradeSeq: '',
          displayStatus: '',
        },
        tableTitle: '评价列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '充电单号', prop: 'tradeSeq', minWidth: '200px' },
          { label: '电站名称', prop: 'stationName', minWidth: '150px' },
          {
            label: '充电用户',
            prop: 'nickName',
            minWidth: '250px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span>
                    {row.userAccount}({row.nickName})
                  </span>
                );
              },
            },
          },
          {
            label: '状态',
            prop: 'replyStatus',
            scopedSlots: {
              default: ({ row }) => {
                const replyStatus = row.replyStatus === 1 ? 'status-blue' : 'status-red';
                return (
                  <span class={['userStatus', replyStatus]}>
                    {+row.replyStatus === 1 ? '已回复' : '未回复'}
                  </span>
                );
              },
            },
            minWidth: '100px',
          },
          { label: '修改时间', prop: 'replyDate', minWidth: '180px' },
          {
            label: '展示状态',
            prop: 'displayStatus',
            scopedSlots: {
              default: ({ row }) => {
                const displayStatus = row.displayStatus === 1 ? 'status-blue' : 'status-red';
                return (
                  <span class={['userStatus', displayStatus]}>
                    {row.displayStatus === 1 ? '显示' : '隐藏'}
                  </span>
                );
              },
            },
            minWidth: '100px',
          },
          { label: '修改人', prop: 'replyPerson', minWidth: '100px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            key: 'date',
            label: '修改时间',
            placeholder: '请选择修改时间',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'tradeSeq',
            label: '充电单号',
            placeholder: '请输入充电单号',
          },
          {
            comName: 'YkcDropdown',
            key: 'displayStatus',
            label: '状态',
            placeholder: '请选择状态',
            data: [
              { id: '', name: '全部' },
              { id: '1', name: '显示' },
              { id: '0', name: '隐藏' },
            ],
          },
        ];
      },
    },
    created() {
      const params = this.$route.query;
      if (params.tradeSeq) {
        this.searchParams.tradeSeq = params.tradeSeq;
      }
      this.requestList();
      this.initData();
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('operation:evaluate:list:reply'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.evaluateId = row.evaluateId;
                  this.showDrawer = true;
                }}>
                回复
              </ykc-button>
            ),
          },
          {
            text: '详情',
            enabled: () => code('operation:evaluate:list:detail'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/operationCenter/orderManage/orderEvaluation/details',
                query: { evaluateId: row.evaluateId },
              });
            },
          },
          {
            enabled: () => code('operation:evaluate:list:enable'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.doAbleActivity(row);
                }}>
                {row.displayStatus === 1 ? '隐藏' : '显示'}
              </ykc-button>
            ),
          },
        ];
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);

        if (searchData.date && searchData.date.length > 0) {
          [this.searchParams.startTime, this.searchParams.endTime] = searchData.date;
        }
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);

        // 近30天初始化时间
        const startDate = getLastTime('yyyy-MM-dd', 30);
        const endDate = getLastTime('yyyy-MM-dd', 0);

        this.searchParams.date = [startDate, endDate];
        this.searchParams.startTime = startDate;
        this.searchParams.endTime = endDate;
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        orderPage
          .orderEvaluateList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * @desc 隐藏显示
       * */
      doAbleActivity(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: `确认${row.displayStatus === 1 ? '隐藏' : '显示'}该回复吗？`,
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            orderPage
              .changeEvaluateDisplay({
                evaluateId: row.evaluateId,
                displayStatus: row.displayStatus === 1 ? 0 : 1,
              })
              .then(() => {
                this.$message.success('操作成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'order_evaluate_export',
          jsonNode: {
            ...this.searchParams,
          },
        });
      },
      /**
       * 自定义列
       * */
      toBlockkeywords() {
        this.$router.push({
          path: '/operationCenter/orderManage/orderEvaluation/blockKeywords',
        });
      },
    },
  };
</script>
